body, html {
  background-color: #eee;
}

.App {
  text-align: center;
  min-height: 100vh;
  color: #1d1e21;
  padding:50px 5px 75px;
}

.app-logo {
  max-height: 30px;
  pointer-events: none;
}



.app-header {
  background-color: #1d1e21;
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.header-content{
  position:relative;
  width:100%;
  
}

.app-header-logo{
 
    text-align:center;
    align-items: center;
    
}

.app-header-logout{
    position:absolute;
    text-align:right;
    right:0px;
    top:0px;
    z-index:10;
}

.textfield {
  border: 1px solid #ababab;
  background-color: #fafafa;
  margin-bottom:10px;
  padding:5px 10px;
  height: 40px;
  line-height:30px;
  font-size: 11px;
  width: 100%;

}

.note {
  margin-top:10px;
  font-size: 10px;
}

.note a, .note a:active, .note a:visited{
  color:#333;

}
.note a:hover{
  text-decoration: none;
}

.event-list{
  margin-top:30px !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-bottom:10px !important;

}

.MuiImageListItem-root.event-card{
  margin-bottom:10px;
  height:40vh !important;
}

.event-card-header{
  text-align:left;
}

.event-media{
  width: 100%;
  max-height: 45vh;
  height: 100vw;
}

.live-container{
  width:100%;
  height: 100%;
  position:relative;
  
}

.live-container .live-close{
  position:absolute;
  top:10px;
  right:10px;
  width: 50px;
  height: 50px;
  z-index:100;
}

.live-container .live-mute{
  position: absolute;
  top:10px;
  left:10px;
  width:50px;
  height: 50px;
  z-index:100
}

.live-container .live-flipcamera{
  position: absolute;
  top:10px;
  left:70px;
  width:50px;
  height: 50px;
  z-index:100
}

.live-container .live-chat-view{
  position: absolute;
  top:10px;
  left:130px;
  width:50px;
  height: 50px;
  z-index:100
}

#livestream-view{
  width: 100%;
  height: 90vh;
  max-height: 100vh;
  min-height: 300px;
  position:relative;
  margin-top:10px;

}

#remote-hosts {
  width: 100%;
  height: 60vh;
  position:relative;
  display:none;
  margin-top:10px
}


#remote-hosts.has-guest{
  display:flex;
  align-items: center;

}

#livestream-view.has-guest{
  height:40vh;
  margin-top:5px;
}

#remote-hosts.has-guest .guest-1{
  width:100%;
}

#remote-hosts.has-guest > div{
  flex: 1;
  display:flex;
  margin-left:1px;
  margin-right:1px;
}




.live-container .chat-log{
  text-align:left;
  height:30vh;
  max-height: 220px;
 
  left:0;
  background-color:rgba(30, 30, 30, 0.3);
  width: 100%;
  
  overflow-y: auto;
  box-sizing: content-box;
}

.chat-log .chat-item{
    margin-bottom:10px;
    position:relative;
    display:block;
    box-sizing: content-box;
    padding: 0px 5px;
}

.chat-log .chat-name{
  color:#f9a521;
  font-weight: bold;
  margin-right:5px;
  font-size: 11px;
  line-height:20px;
  display:inline-block;
  height:20px;
  vertical-align: middle;
  
}

.chat-log .chat-avatar{
  width:20px;
  height:20px;
  display:inline-block;
  margin-right:5px;
  vertical-align: middle;
}

.chat-log .chat-avatar.MuiListItemIcon-root{
  width:30px;
  min-width:30px;
}

.chat-log .chat-content{
    width: 70%;
    display:inline-block;
    margin-right:5px;
    vertical-align:middle;
}

.chat-log .chat-avatar .MuiAvatar-root{
    width:20px;
    height:20px;
}

.chat-log .chat-msg{
    color:#FFF;
    font-size:11px;
    display:inline-block;
    line-height:20px;
    vertical-align: middle;
}



.event-new-message{
  background-color:#fff;
  border:1px solid #ccc;
  padding: 5px 10px;

}

.event-chat-wrapper{
    height: 250px;
    max-height: 30vh;
    position:absolute;
    bottom:-5px;
    width: 100%;
}

.event-chat-wrapper .event-chat-log{
  margin-bottom:25px;
}

.MuiCardContent-root{
  padding:0;
}

.MuiCardContent-root p {
  text-align:left;
  margin-top:0;
}

.MuiContainer-root{
  padding-left:0 !important;
  padding-right:0 !important;

}


